<template>
    <div class="page-container">
        <img class="banner" src="../../assets/image/guanwang_bg_jianjie.png" alt="">
        <div class="page-content-div">
            <div class="page-content">
               <div class="lingyu-content">
                   <div class="lingyu-left">
                       <p class="lingyu-left-title">应用范围</p>
                       <div class="type-content">
                           <div v-for="(item, index) in typeList" :key="index"
                                :class="{active: activeType==item.value}"
                                class="type-item">{{item.name}}</div>
                       </div>
                   </div>
                   <div class="lingyu-right">
                       <div class="product-content">
                           <div class="product-item">
                               <div class="product-item-left">
                                   <div class="product-img-content"></div>
                               </div>
                               <div class="product-item-right">
                                   <p class="title">PE重包装袋(FFS袋)</p>
                                   <div class="detail">
                                       PE重包装袋为FFS袋的统称，为制（Film)—装料（Fill)—封口 (Seal) 一体化的缩写，是欧洲最新发展起来的一种高速包装技术，包装效 率高且成本低，是包装行业中的新兴产品PE重包装袋为FFS袋的统称，为制（Film)—装料（Fill)—封口 (Seal) 一体化的缩写，是欧洲最新…                                   </div>

                                   <div class="fangan-content">
                                       <div class="fangan"><span>解决方案</span> <i class="el-icon-arrow-right"></i></div>
                                       <div class="dingzhi"><span>立即定制</span> <i class="el-icon-arrow-right"></i></div>
                                   </div>
                               </div>

                           </div>
                           <div class="product-item">
                               <div class="product-item-left">
                                   <div class="product-img-content"></div>
                               </div>
                               <div class="product-item-right">
                                   <p class="title">PE阀口袋</p>
                                   <div class="detail">
                                       PE阀口袋，俗称糊底袋，为目前国际流行包装 袋，从顶部或底部阀口进料，采用专门灌装设备，灌 装物料后装成方体，堆包整齐，美观，属于环保袋                                   </div>
                                   <div class="fangan-content">
                                       <div class="fangan"><span>解决方案</span> <i class="el-icon-arrow-right"></i></div>
                                       <div class="dingzhi"><span>立即定制</span> <i class="el-icon-arrow-right"></i></div>
                                   </div>
                               </div>

                           </div>
                       </div>
                   </div>
               </div>

            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                typeList: [
                    {name: '化工', value: 1},
                    {name: '塑料', value: 2},
                    {name: '建材', value: 3},
                    {name: '农肥', value: 4},
                    {name: '饲料', value: 5},
                    {name: '食品', value: 6},
                    {name: '其他用途', value: 7},
                ],
                activeType: 1
            };
        }
    }
</script>

<style lang="scss" scoped>
    .page-container .banner{
        width: 100%;
    }
    .page-content-div{
        display: flex;
        justify-content: center;
        width: 100%;
        .page-content{
            width: 1200px;
            .lingyu-content{
                display: flex;
                justify-content: space-between;
                .lingyu-left{
                    width: 180px;
                    border-left: 1px solid rgba(233, 233, 238, 1);
                    border-right: 1px solid rgba(233, 233, 238, 1);
                    border-bottom: 1px solid rgba(233, 233, 238, 1);
                    padding: 20px 0;
                    .lingyu-left-title{
                        color: rgba(5, 6, 16, 100);
                        font-size: 24px;
                        padding-left: 16px;
                        padding-bottom: 10px;
                    }
                    .type-content{
                        .type-item{
                            color: rgba(5, 6, 16, 100);
                            font-size: 20px;
                            padding-left: 24px;
                            height: 56px;
                            line-height: 56px;
                            margin-top: 10px;
                            cursor: pointer;
                        }
                        .active{
                            color: rgba(57, 63, 227, 100);
                            background: linear-gradient(90deg,#d8d9ff,rgba(57, 63, 227, 0));
                        }
                    }
                }
            }
        }
    }

    .lingyu-right{
        margin-left: 24px;
        flex: 1;
    }
    .product-content{
        width: 100%;
        .product-item{
            display: flex;
            margin-top: 50px;
            margin-bottom: 20px;
            .product-item-left{
                width: 282px;
                .product-img-content{
                    width: 280px;
                    height: 280px;
                    border: 1px solid rgba(233, 233, 238, 1);
                }
            }
            .product-item-right{
                flex: 1;
                margin-left: 40px;
                .title{
                    color: rgba(5, 6, 16, 100);
                    font-size: 28px;
                }
                .detail{
                    line-height: 28px;
                    color: rgba(114, 114, 122, 100);
                    font-size: 20px;
                    margin-top: 20px;
                }

                .fangan-content{
                    display: flex;
                    margin-top: 24px;
                    .fangan{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid rgba(233, 233, 238, 1);
                        height: 60px;
                        padding: 0 30px;
                        color: rgba(57, 63, 227, 100);
                        font-size: 20px;
                        margin-right: 10px;
                    }
                    .dingzhi{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid rgba(233, 233, 238, 1);
                        height: 60px;
                        color: #fff;
                        font-size: 20px;
                        background: #393FE3;
                        padding-left: 30px;
                        padding-right: 10px;
                        position: relative;
                        &::before{
                            position: absolute;
                            content: '';
                            right: -20px;
                            top: 0;
                            width: 0;
                            height: 0;
                            border: 30.5px solid;
                            border-width: 30.5px 0 30.5px 20px;
                            border-color: transparent transparent transparent #393FE3;
                        }
                    }
                }
            }
        }
    }
</style>